<template>
  <PostError>
    <Dialog
      v-model="dialogStatus"
      :type="'fail'"
      :content-title="'邮箱激活失败'"
    />
  </PostError>
</template>
<script>
import PostError from '@/component/post-error/index.vue';
import Dialog from '@/ui/dialog/index.vue';
import { setTitle } from '@/util/util';

export default {
  components: {
    PostError,
    Dialog,
  },
  props: {
  },
  data() {
    return {
      dialogStatus: true,
    };
  },
  created() {
    setTitle('邮箱激活失败');
  },
};
</script>
