<template>
  <PostError>
    <p class="page-email-activation-main">
      404~找不到呀找不到~
    </p>
  </PostError>
</template>
<script>
import PostError from '@/component/post-error/index.vue';
import { setTitle } from '@/util/util';

export default {
  components: {
    PostError,
  },
  props: {
  },
  data() {
    return {
    };
  },
  created() {
    setTitle('404');
  },
};
</script>

<style lang="scss">
.page-email-activation-main {
  margin: 0 auto;
  width: 80%;
  text-align: center;
}
</style>
